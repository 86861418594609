import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { AxiosResponse } from 'axios';
import { IQueryPage, TSearchParams, IStatus, TApiError } from '@shared/types/global';
import { IInvoiceListItem, TInvoiceListFilter } from '@shared/types/interfaces/invoice.interface';

interface IInvoiceList {
  data: IInvoiceListItem[];
  isOpenFilter: boolean;
  filter: TInvoiceListFilter;
  page: number;
  count: number;
  total: number;
  totalPage: number;
  isLoading: boolean;
  error: TApiError | null;
}

interface IInvoiceListResponse extends IQueryPage {
  data: IInvoiceListItem[];
  total: number;
}

type TInvoiceListResponse = AxiosResponse<IInvoiceListResponse>;

export const initialInvoiceListFilter: TInvoiceListFilter = {
  _id: '',
  number: '',
  status: { _id: '', name: '' },
  type: { _id: '', name: '' },
  contractor: { _id: '', name: '' },
  contract: { _id: '', number: '' },
  startDateAt: '',
  endDateAt: '',
  minSum: '',
  maxSum: '',
  page: 1,
  count: 30,
};

const initialState: IInvoiceList = {
  data: [],
  isOpenFilter: false,
  filter: initialInvoiceListFilter,
  page: 1,
  totalPage: 1,
  count: 0,
  total: 0,
  isLoading: true,
  error: null,
};

export const getInvoiceListData = createAsyncThunk<
  TInvoiceListResponse,
  TSearchParams,
  { rejectValue: TApiError }
>('invoiceList/getInvoiceListData', async (params, { rejectWithValue }) => {
  try {
    const res = await server.get('/invoice/list', { params });
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const invoiceListAdapter = createEntityAdapter();

const invoiceListSlice = createSlice({
  name: 'invoiceList',
  initialState: invoiceListAdapter.getInitialState<IInvoiceList>(initialState),
  reducers: {
    handleOpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    changeFilter: (state, action: PayloadAction<Partial<TInvoiceListFilter>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    changeFilterStatus: (state, action: PayloadAction<IStatus[]>) => {
      const filterStatus = action.payload.find(
        (item) => item.id === Number(state.filter.status._id),
      );

      if (filterStatus) {
        state.filter.status = { _id: filterStatus.id.toString(), name: filterStatus.name };
      } else {
        state.filter.status = { _id: '', name: '' };
      }
    },
    resetFilter: (state) => {
      state.filter = initialInvoiceListFilter;
    },
    resetInvoiceList: (state) => {
      state.data = [];
      state.isOpenFilter = false;
      state.filter = initialInvoiceListFilter;
      state.page = 1;
      state.totalPage = 1;
      state.count = 0;
      state.total = 0;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceListData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInvoiceListData.fulfilled, (state, action) => {
        const { data, page, count, total } = action.payload.data;

        state.isLoading = false;
        state.data = data;
        state.page = page;
        state.count = count;
        state.total = total;
        state.totalPage = Math.ceil(total / count);
      })
      .addCase(getInvoiceListData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? null;
      });
  },
});

export const invoiceListSelector = {
  getState: (state: { invoiceList: IInvoiceList }) => state.invoiceList,
  getData: (state: { invoiceList: IInvoiceList }) => state.invoiceList.data,
  getFilter: (state: { invoiceList: IInvoiceList }) => state.invoiceList.filter,
};

export const { handleOpenFilter, changeFilter, changeFilterStatus, resetFilter, resetInvoiceList } =
  invoiceListSlice.actions;

export type { TInvoiceListResponse };

export default invoiceListSlice.reducer;
